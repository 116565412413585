<template>
  <div class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>About Vasion</h1>
    </div>
    <div class="vap-page-content">
      <p>{{ lastUpdateDate }}</p>
      <div class="about-vasion-table-container vasion-html-table-default">
        <VasionTable
          :headerColumns="ColumnValues"
          :tableRows="tableData.Rows.Values"
          :filterByColumnDefault="1"
          :clickableRows="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import { formatDateTime } from '../../../store/helperModules/common.module';

export default {
  name: 'AboutVasion',
  components: {
    Loading,
  },
  data: function () {
    return {
      ColumnValues: [
        'Component',
        'Version',
        'Release Date',
      ],
      isLoading: false,
      lastUpdateDate: '',
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      tableData: {
        Rows: {
          Values: [],
        },
      },
    }
  },
  computed: {
    uiVersion() { return this.$store.state.admin.uiVersion }
  },
  async created() {
    await this.refreshTable()
  },
  methods: {
    async refreshTable() {
      this.isLoading = true

      if (!this.uiVersion) {
        await (this.$store.dispatch('admin/getUiVersion'))
      }
      const appVersions = await this.$store.dispatch('admin/getAppVersions')
      this.lastUpdateDate = 'The Vasion API site was last updated on ' + (appVersions.UpdateDate ? formatDateTime(appVersions.UpdateDate, 'date') : "") + '.'

      this.tableData = {
        Rows: {
          Values: [],
        },
      }
      appVersions.Apps.forEach(app => {
        this.tableData.Rows.Values.push({
          Values: [
            app.AppName,
            app.Version,
            app.ReleaseDate ? formatDateTime(app.ReleaseDate, 'date') : "",
          ]
        })
      })

      this.tableData.Rows.Values.push( {
        Values: [
          "UI",
          this.uiVersion,
          "",
        ],
      })
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>

.about-vasion-table-container {
  height: calc(100vh - 249px);
  overflow: auto;
  text-align: left;
}
</style>
